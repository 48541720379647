import React from 'react';
import styles from './StepByStep.module.css'; // Import the CSS module

const StepByStep = () => {
  return (
    <div className={styles.container}>
    <div className={styles.screen}>
      <div className={styles.main}>
        <img src="https://i.ibb.co.com/F4VK6KHs/header.jpg" alt="Header" />
        <div className={styles.player}>
          <img src="https://i.ibb.co.com/6cGq6byM/player1.jpg" alt="Player 1" />
          <img src="https://i.ibb.co.com/0VDjJdXV/player2.jpg" alt="Player 2" />
          <img src="https://i.ibb.co.com/8D3mSp4g/player3.jpg" alt="Player 3" />
          <img src="https://i.ibb.co.com/tw7KkmZ1/player4.jpg" alt="Player 4" />
          <img src="https://i.ibb.co.com/9k4PHW5W/player5.jpg" alt="Player 5" />
          
          <div>
            <img src="https://i.ibb.co.com/TBYM6htX/search.jpg" alt="Search" />
            <div></div>
          </div>
          
          <img src="https://i.ibb.co.com/cSqD0LKR/music1.jpg" alt="Music 1" />
          <img src="https://i.ibb.co.com/Q737mTn5/music2.jpg" alt="Music 2" />
          <img src="https://i.ibb.co.com/Rk4tQW6M/music3.jpg" alt="Music 3" />
          <div className={styles.borderRight}></div>
          <div className={styles.whiteBg}></div>
        </div>
        <div>
          <img src="https://i.ibb.co.com/4z5zdsS/body.jpg" alt="Body" />
          <div className={styles.escappucino}>
            <img src="https://i.ibb.co.com/yFvrPX8z/pesan.png" alt="Escappucino" />
            <img src="https://i.ibb.co.com/rRwPHtY7/pesan-1.png" alt="Escappucino 1" />
          </div>
          <div className={styles.chickenkatsu}>
            <img src="https://i.ibb.co.com/yFvrPX8z/pesan.png" alt="Chicken Katsu" />
            <img src="https://i.ibb.co.com/rRwPHtY7/pesan-1.png" alt="Chicken Katsu 1" />
          </div>
        </div>
      </div>
      <div className={styles.cartbutton}>
        <img src="https://i.ibb.co.com/zVrfGjZw/New-Project.png" alt="New Project" />
        <img src="https://i.ibb.co.com/Y 7wbjGDz/cart-2.png" alt="Cart" />
      </div>
      <div className={styles.cart}>
        <img src="https://i.ibb.co.com/F4Hb7Tqg/cart.jpg" alt="Cart" />
        <img src="https://i.ibb.co.com/Mxrjc9Dc/checkout.png" alt="Checkout" />
      </div>
      <div className={styles.transaction}>
        <img src="https://i.ibb.co.com/1p7pnnD/transaction.png" alt="Transaction" />
      </div>
    </div>
    <div className={styles.secondscreen}>
      <div className={styles.secondmain}>
        <img src="https://i.ibb.co.com/LDw21htp/New-Project.jpg" alt="Second Main" />
      </div>
    </div>
    </div>
  );
};

export default StepByStep;