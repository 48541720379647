// LinktreePage.js
import React, { useState, useEffect } from 'react';
import styles from './Join.module.css'; // Import the module.css file

const LinktreePage = ({ handleYes }) => {
  const [captMessage, setCaptMessage] = useState('');
  const [descMessage, setDescMessage] = useState('');

  useEffect(() => {
      const newQueryParams = new URLSearchParams(window.location.search);
      const r = newQueryParams.get('captMessage');
      const s = newQueryParams.get('descMessage');
      if (r) {
        setCaptMessage(r)
        setDescMessage(s)
      }
  }, []);

  return (
    <div className={styles.linktreePage}>
        <div className={styles.dashboardContainer}>
          <div className={styles.mainHeading}>{captMessage}</div>
          <div className={styles.descHeading}>{descMessage}</div>
          {handleYes && <div onClick={handleYes} className={styles.button}>Aktifkan</div>}
        </div>
    </div>
  );
};

export default LinktreePage;
